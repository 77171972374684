import React from 'react'
import Footer from '../../components/Footer/Footer';
import { LocaleTypes } from '../../data/locales';
import IndexLayout from '../../layouts';
import { FormattedMessage } from 'react-intl'

interface ConditionTermsProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const companies: React.FC<ConditionTermsProps> = ({pathContext, location}) => {

  const companies = [ 
    {
      country: "MÉXICO",
      name: "KUSHKI GROUP US, LLC",
      tax: "RFC: KUS1812121C1",
      address: "Paseo de la Reforma No 509, Piso 16, Col. Cuauhtémoc, Alcaldía Cuauhtémoc, en CDMX, México CP 06500"
    },
    
    {
      country: "COLOMBIA",
      name: "Kushki Colombia S.A.S.",
      tax: "NIT. 901.000.330-4",
      address: "CALLE 80 #11- 42 Piso 5, Despacho 502, Edificio 81 TO SUR, COLOMBIA, BOGOTÁ"
    },
    {
      country: "ECUADOR",
      name: "Kushki, S.A.",
      tax: "RUC. 1792574218001",
      address: "Av. Eloy Alfaro N34-194, Pichincha, Quito- Pichincha Edificio Corporativo 194, Piso 11"
    },
    {
      country: "PERÚ",
      name: "Kushki Perú SRL",
      tax: "RUC. 20603543581",
      address: "Av. Larco #1232, Piso 3, Miraflores, Lima Perú"
    },
  ]



  const content = () => (
    <>
      <section className="legal-hero">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <h1><FormattedMessage id="condition_terms.companies.title" /></h1>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage id="condition_terms.companies.title_t1"/></th>
                <th scope="col"><FormattedMessage id="condition_terms.companies.title_t2"/></th>
                <th scope="col"><FormattedMessage id="condition_terms.companies.title_t3"/></th>
                <th scope="col"><FormattedMessage id="condition_terms.companies.title_t4"/></th>
              </tr>
            </thead>
            <tbody>
              {
                companies.map((company, index) => (
                  <tr>
                    <th scope="row">{company.country}</th>
                    <td>{company.name}</td>
                    <td>{company.tax}</td>
                    <td>{company.address}</td>
                  </tr>
                ))
              }
              <tr>
                <th scope="row" rowSpan="2"  >CHILE</th>
                <td>Kushki Chile SpA</td>
                <td>RUT. 76.693.142−1</td>
                <td>Los Militares 4611 OF 171 , Las Condes, Santiago de Chile</td>
              </tr>
              <tr>
                <td>KUSHKI Operadora, S.A.</td>
                <td>RUT. 8868319-6</td>
                <td>Napoleón No. 3565, Of 812 Las Condes</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <Footer theme="gray" />
    </>
  )

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default companies